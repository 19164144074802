
import React from "react"
import { Link } from 'gatsby'
import { formatDate } from "../utils/common"

class PostPreviewTile extends React.Component {
  render() {
    const post = this.props.post
    const coverImage = post.fields.coverImage
    const type = post.type || 'tutorial'
    const link = `/${type}/${post.id}`

    const getAuthorInfo = () => {
      if (type !== 'share') return null;

      return (
        <p className="is-1">{post.contributor.name} {formatDate(post.date)}</p>
      );
    };

    return (
      <div className="column is-6 is-4-widescreen is-flex">
        <div className="card card-equal-height">
          <div className="card-image">
            <figure className="image is-4by3">
              <img src={coverImage} alt={post.title} />
            </figure>
          </div>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-4">{post.title}</p>
                {getAuthorInfo()}
              </div>
            </div>
            <div className="content">{post.excerpt}</div>
          </div>
          <div className="card-footer">
            <Link to={link} className="card-footer-item">阅读</Link>
          </div>
        </div>
      </div>
    )
  }
}

export default PostPreviewTile
