import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import { graphql } from "gatsby"

import PostPreviewTile from "../components/post-preview-tile"

class BlogPostsIndex extends React.Component {
  render() {
    const { allStrapiArticle } = this.props.data

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet>
            <title>博客</title>
          </Helmet>
          <div className="columns is-multiline">
            {allStrapiArticle.edges.map(({ node }) => (
              <PostPreviewTile
                post={node}
                key={node.id}
              />
            ))}
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostsIndex

export const pageQuery = graphql`
  query {
    allStrapiArticle(
      sort: { order: DESC, fields: [updatedAt] }
      filter: {type: {eq: "blog"}}
    ) {
      edges {
        node {
          id: strapiId
          excerpt
          title
          updatedAt(formatString: "YYYY-MM-DD")
          date
          coverUrl
          fields {
            coverImage
          }
          type
          author {
            id
            username
          }
        }
      }
    }
  }
`
